const histories = [
	{
		text: 'Users',
		align: 'start',
		sortable: false,
		value: 'user'
	},
	{ text: 'Date', value: 'date' },
	{ text: 'Amount', value: 'amount' },
	{ text: 'Old Balance', value: 'old_balance' },
	{ text: 'New Balance', value: 'new_balance'},
];

export default histories;
